export const menuItems = [
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        badge: {
            variant: "success",
            text: "menuitems.dashboard.badge"
        },
        link: '/'
    },
    {
        id: 3,
        label: 'menuitems.micoapp.text',
        icon: 'fas fa-globe-americas',
        link: '/micoapp'
    },
    {
        id: 18,
        isLayout: true
    }
]